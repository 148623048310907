<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-form ref="form" :model="form" label-width="90px">
					<el-row :gutter="10">
						<el-col :span="5" :xs="24">
							<el-form-item label="日期"><el-date-picker v-model="form.valueData" type="date" value-format="YYYYMMDD" placeholder="请选择日期" /></el-form-item>
						</el-col>
						<el-col :span="5" :xs="24">
							<el-form-item label="变现平台">
								<el-select v-model="form.adSource" clearable placeholder="变现平台">
									<el-option :key="1" label="百度" :value="1" />
									<el-option :key="100" label="广点通" :value="100" />
									<el-option :key="101" label="穿山甲" :value="101" />
									<el-option :key="107" label="快手" :value="107" />
									<el-option :key="503" label="vivo" :value="503" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="5" :xs="24">
							<el-form-item label="广告位类型">
								<el-select v-model="form.adInfoType" clearable placeholder="广告位类型">
									<el-option :key="1" label="横幅" :value="1" />
									<el-option :key="2" label="开屏" :value="2" />
									<el-option :key="3" label="插屏" :value="3" />
									<el-option :key="4" label="信息流" :value="4" />
									<el-option :key="5" label="激励视频" :value="5" />
									<el-option :key="7" label="全屏视频" :value="7" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="5" :xs="24">
							<el-form-item label="上游代码位"><el-input v-model="form.pid" clearable placeholder="请输入上游代码位"></el-input></el-form-item>
						</el-col>
						<el-col :span="4" :xs="24" class="el">
							<el-button type="primary" @click="jouShare">搜索</el-button>
							<el-button type="primary" @click="reimportcl">重新导入</el-button>
						</el-col>
					</el-row>
				</el-form>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" style="width: 100%" border stripe>
					<el-table-column prop="day" label="日期"></el-table-column>
					<el-table-column prop="adSourceDesc" label="变现平台"></el-table-column>
					<el-table-column prop="adInfoTypeDesc" label="广告位类型"></el-table-column>
					<el-table-column prop="pid" label="上游广告位id"></el-table-column>
					<el-table-column prop="view" label="曝光量"></el-table-column>
					<el-table-column label="ECPM">
						<template v-slot="scope">
							<span v-if="scope.row.view != 0">{{ ((scope.row.income / scope.row.view) * 1000).toFixed(2) }}</span>
							<span v-else>0</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 重新导入 -->

				<el-dialog v-model="reimportVisible" title="重新导入" width="30%">
					<el-form label-width="80px" :model="formreimport">
						<el-form-item label="日期"><el-date-picker v-model="formreimport.reortvalue" type="date" value-format="YYYYMMDD" placeholder="请选择日期" /></el-form-item>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="reimportClick">确定</el-button>
							<el-button @click="reimportVisible = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'upstreamData',
	data() {
		return {
			reimportVisible: false,
			formreimport: {
				reortvalue: ''
			},
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			form: {
				valueData: '',
				adSource: '',
				pid: '',
				adInfoType: ''
			}
		};
	},
	created() {
		const start = new Date();
		start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
		this.form.valueData = this.$moment(start).format('YYYYMMDD');

		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 上游数据
		this.originalData();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 上游数据
				this.originalData();
			}
		}
	},
	methods: {
		// 上游数据
		originalData() {
			this.serloading = true;
			this.$HTTP.post(
				'originalData/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					adInfoType: this.form.adInfoType,
					adSource: this.form.adSource,
					day: this.form.valueData,
					pid: this.form.pid
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			// 上游数据
			this.originalData();
		},
		// 重新导入
		reimportcl() {
			(this.reimportVisible = true), (this.formreimport.reortvalue = this.$moment(new Date().getTime() - 3600 * 1000 * 24 * 1).format('YYYYMMDD'));
		},
		reimportClick() {
			this.serloading = true;
			this.reimportVisible = false;
			this.$HTTP.post(
				'originalData/resetOriginalData',
				{
					day: this.formreimport.reortvalue,
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.serloading = false;
							// 上游数据
							this.originalData();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},

		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 上游数据
			this.originalData();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 上游数据
			this.originalData();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
